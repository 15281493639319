import { MenuProps } from 'antd'
import dayjs from 'dayjs'

// menuData.ts
export const roleData: MenuProps['items'] | any = [
  { key: '1', label: 'Оператор Весовой' },
  { key: '2', label: 'Менеджер Отдела Сбыта' },
  { key: '3', label: 'Ст. Менеджер Отдела Сбыта' },
  { key: '4', label: 'Директор месторождения' },
  { key: '5', label: 'Учредитель' },
  { key: '6', label: 'Бухгалтер' },
]

export const companyTypes: MenuProps['items'] | any = [
  { key: '1', label: 'ТОО' },
  { key: '2', label: 'ИП' },
  { key: '3', label: 'Частное лицо' },
]

export const companyFunction: MenuProps['items'] | any = [
  { key: '1', label: 'Заказчик' },
  { key: '2', label: 'Поставщик' },
]

export const bornData: MenuProps['items'] | any = [
  { key: '1', label: 'Все' },
  { key: '2', label: 'Эверест' },
  { key: '3', label: 'Северный' },
  { key: '4', label: 'Козыкош' },
]

export const anotherLimit: MenuProps['items'] | any = [
  { key: '1', label: 'Отсутствует' },
  { key: '2', label: '10%' },
  { key: '3', label: '20%' },
  { key: '4', label: '30%' },
]

export const allRequests: string[] = [
  'Открытые',
  'Открытые, Незавершенные на сегодня',
  'Открытые, Завершенные на сегодня',
  'Полностью завершенные',
  'Деактивированные',
]

export const allBorns: string[] = [
  'Все',
  'м/р Эверест',
  'м/р Северный',
  'м/р Козыкош',
]

export const allUnits: MenuProps['items'] | any = [
  { key: '1', label: 'Тонны' },
  { key: '2', label: 'Кубы' },
]

export const allPriority: MenuProps['items'] | any = [
  { key: '1', label: 'Высокий' },
  { key: '2', label: 'Средний' },
  { key: '3', label: 'Низкий' },
]

export const exportType: MenuProps['items'] | any = [
  { key: '1', label: 'Отдел сбыта' },
  { key: '2', label: 'Наёмник' },
  { key: '3', label: 'Отдел сбыта + Наёмник' },
  { key: '4', label: 'Самовывоз безлимитный' },
  { key: '5', label: 'Самовывоз с лимитом' },
  { key: '6', label: 'Самовывоз одноразовый' },
]

export const exportWeighingType: MenuProps['items'] | any = [
  { key: '1', label: 'Отдел сбыта' },
  { key: '2', label: 'Наёмник' },
  { key: '3', label: 'Самовывоз' },
]

export const exportBriefCaseType: MenuProps['items'] | any = [
  { key: '1', label: 'Доставка' },
  { key: '2', label: 'Самовывоз' },
]

export const parseCompanyType = (data: string | any) =>
  companyTypeTranslations[data] || data
export const parseRole = (data: string | any) => roleTranslations[data] || data
export const parseBorn = (data: string | any) => bornTranslations[data] || data
export const parseShip = (data: string | any) =>
  shippingTranslations[data] || data
export const parsePriority = (data: string | any) =>
  priorityTranslations[data] || data
export const parseLimit = (data: string | any) =>
  limitTranslations[data] || data
export const parseUnit = (data: string | any) => unitTranslations[data] || data
export const parseStatus = (data: string | any) =>
  statusTranslations[data] || data
export const parseCompanyFunction = (data: string | any) =>
  companyFunctionTranslations[data] || data
export const parseWeighingHistory = (data: string | any) =>
  weighingHistoryTranslations[data] || data

export const parseFulfilling = (data: string | any) =>
  data
    ? data === 'Min'
      ? 'Дневной минимум (min)'
      : 'Дневной максимум (max)'
    : 'Неизвестно'

export const parseDate = (data: string): string =>
  data ? dayjs(data).format('DD.MM.YYYY') : 'Неизвестно'

export const getDate = (text: string) => {
  const now = new Date()
  const real = now.toISOString().slice(0, 19).replace('T', ' ').split(' ')
  if (text === 'today') return `${real[0]} 00:00:00`
  if (text === 'tomorow') return `${real[0]} 23:59:59`
}

const companyTypeTranslations: any = {
  ТОО: 'TOO',
  ИП: 'IP',
  'Частное лицо': 'PP',
  TOO: 'ТОО',
  IP: 'ИП',
  PP: 'Частное лицо',
}

const roleTranslations: any = {
  'Оператор Весовой': 'WeighingOperator',
  'Директор месторождения': 'FieldDirector',
  'Менеджер Отдела Сбыта': 'SalesDepartmentManager',
  'Ст. Менеджер Отдела Сбыта': 'SeniorSalesDepartmentManager',
  Учредитель: 'Founder',
  Бухгалтер: 'Accountant',
  РОП: 'ROP',
  WeighingOperator: 'Оператор Весовой',
  FieldDirector: 'Директор месторождения',
  SalesDepartmentManager: 'Менеджер Отдела Сбыта',
  SeniorSalesDepartmentManager: 'Ст. Менеджер Отдела Сбыта',
  Founder: 'Учредитель',
  Accountant: 'Бухгалтер',
  ROP: 'РОП',
  All: 'Все',
  Все: 'All',
}

const bornTranslations: any = {
  Все: 'All',
  Эверест: 'Everest',
  Северный: 'Northern',
  Козыкош: 'Kozykosh',
  All: 'Все',
  Everest: 'Эверест',
  Northern: 'Северный',
  Kozykosh: 'Козыкош',
}

const shippingTranslations: any = {
  Employee: 'Наёмник',
  Pickup: 'Самовывоз',
  SalesDepartment: 'Отдел сбыта',
  SalesDepartmentAndEmployee: 'Отдел сбыта + Наёмник',
  UnlimitedPickup: 'Самовывоз безлимитный',
  LimitedPickup: 'Самовывоз с лимитом',
  OnetimePickup: 'Самовывоз одноразовый',
  Delivery: 'Доставка',

  Наёмник: 'Employee',
  Самовывоз: 'Pickup',
  'Отдел сбыта': 'SalesDepartment',
  Доставка: 'Delivery',
  'Отдел сбыта + Наёмник': 'SalesDepartmentAndEmployee',
  'Самовывоз безлимитный': 'UnlimitedPickup',
  'Самовывоз с лимитом': 'LimitedPickup',
  'Самовывоз одноразовый': 'OnetimePickup',
}

const priorityTranslations: any = {
  Low: 'Низкий',
  Normal: 'Средний',
  High: 'Высокий',
  Низкий: 'Low',
  Средний: 'Normal',
  Высокий: 'High',
}

const limitTranslations: any = {
  No: 'Отсутствует',
  Low: '10%',
  Normal: '20%',
  High: '30%',
  Отсутствует: 'No',
  '10%': 'Low',
  '20%': 'Normal',
  '30%': 'High',
}

const unitTranslations: any = {
  Tons: 'Тонны',
  Cubes: 'Кубы',
  Тонны: 'Tons',
  Кубы: 'Cubes',
}

const companyFunctionTranslations: any = {
  Customer: 'Заказчик',
  Provider: 'Поставщик',
  Поставщик: 'Provider',
  Заказчик: 'Customer',
}

const statusTranslations: any = {
  Open: 'Открыто',
  OpenTodayNotFinished: 'Открытые, Незавершенные на сегодня',
  OpenTodayFinished: 'Открытые, Завершенные на сегодня',
  Finished: 'Полностью завершенные',
  Deactivated: 'Деактивированные',
  'Полностью завершенные': 'Finished',
  Деактивированные: 'Deactivated',
  'Открытые, Завершенные на сегодня': 'OpenTodayFinished',
  'Открытые, Незавершенные на сегодня': 'OpenTodayNotFinished',
  Открытые: 'Open',
  Sended: 'Отправлен',
  Canceled: 'Отменено',
  Отправлен: 'Sended',
  Отменено: 'Canceled',
}

const weighingHistoryTranslations: any = {
  'WeighingState.Canceled': 'Отменено',
  'WeighingState.Sended': 'Отправлено',
  cubic_capacity: 'Кубатура',
  operator_name: 'ФИО водителя',
  car_number: 'Гос.номер',
  rq_date_create: 'Дата создания',
  requisition_id: 'ID заявки',
  weight_gross: 'Брутто',
  weight_netto: 'Нетто',
  weight_tare: 'Тара',
  status: 'Статус',
  ts_weighing_id: 'ID TS',
}

export const config: any = {
  provider_id: 4,
  company_id: 30,
}
